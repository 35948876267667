
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="ma-4">Orders : </h1>
			<!-- <form @submit.prevent="addOrders" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="orders.order_date" type="datetime-local" :label="$store.getters.language.data.orders.order_date" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="orders.order_shipping_price" type="text" :label="$store.getters.language.data.orders.order_shipping_price" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['unpacked','packed']" v-model="orders.order_status" dense  filled outlined  item-text="order_status"
                                    item-value="order_status" :return-object="false" :label="$store.getters.language.data.orders.order_status">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="orders.order_packing_date" type="date" :label="$store.getters.language.data.orders.order_packing_date" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="orders.order_total_price" type="text" :label="$store.getters.language.data.orders.order_total_price" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="orders.order_note" type="text" :label="$store.getters.language.data.orders.order_note" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="customers" v-model="orders.customer_id" dense  filled outlined  item-text="customer_id"
                                    item-value="customer_id" :return-object="false" :label="$store.getters.language.data.customers.customer_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="users" v-model="orders.user_id" dense  filled outlined  item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.orders.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form> -->
			<!-- add search text-field -->

			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" class="ma-3" append-icon="mdi-magnify" label="search" dense outlined hide-details></v-text-field>

					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="order_id">
								<!-- date and time -->
								<template v-slot:[`item.order_id`]="{ item }">
									<div>
										<v-btn icon :to="'/orders-list/'+item.order_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectOrders(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
                                <!-- template to show date and time formated -->
                                <template v-slot:[`item.order_date`]="{ item }">
                                    <div>
                                        {{new Date(item.order_date).toLocaleString()}}
                                    </div>
                                </template>
                                <!-- template to show order_shipping_price with a dolar -->
                                <template v-slot:[`item.order_shipping_price`]="{ item }">
                                    <div>
                                        $ {{item.order_shipping_price}} 
                                    </div>
                                </template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteOrdersList">{{$store.getters.language.data.orders.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					<p>Are you sure you want to delete this order ?</p>
				</v-card-title>
				<v-card-text>
					<v-layout row wrap>
						<v-flex xs12>

							<p> Note: if the order have items it wont be deleted untill you delete the items first</p>
						</v-flex>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.orders.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteOrders(selected_orders.order_id)">
						{{$store.getters.language.data.orders.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/orders.request.js'
	export default {
		data() {
			return {
				orders: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_orders: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.orders.order_date,
						align: 'start',
						sortable: true,
						value: 'order_date',
					},
					{
						text: this.$store.getters.language.data.orders.order_shipping_price,
						align: 'start',
						sortable: true,
						value: 'order_shipping_price',
					},
					// { 
					//     text: this.$store.getters.language.data.orders.order_status,
					//     align: 'start',
					//     sortable: true,
					//     value: 'order_status',
					// },
					// { 
					//     text: this.$store.getters.language.data.orders.order_packing_date,
					//     align: 'start',
					//     sortable: true,
					//     value: 'order_packing_date',
					// },
					// { 
					//     text: this.$store.getters.language.data.orders.order_total_price,
					//     align: 'start',
					//     sortable: true,
					//     value: 'order_total_price',
					// },
					{
						text: this.$store.getters.language.data.customers.customer_username,
						align: 'start',
						sortable: true,
						value: 'customer_username',
					},
					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					},
					{
						text: this.$store.getters.language.data.orders.order_note,
						align: 'start',
						sortable: true,
						value: 'order_note',

					},
					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'order_id',
					}
				],
			}
		},
		computed: {
			customers() {
				return this.$store.getters.customers_list
			},
			users() {
				return this.$store.getters.users_list
			},
		},
		mounted() {
			this.readOrders();
		},
		methods: {
			addOrders() {
				this.loading_btn = true
				requests.createOrders(this.orders).then(r => {
					if (r.status == 200) {
						this.orders = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'Orders Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Orders',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteOrders(order_id) {
				requests.deleteOrders(order_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.order_id != order_id
						})
						this.snackbar = {
							value: true,
							text: 'Orders Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteOrdersList() {
				let ids = this.selected_rows.map(m => m.order_id)
				requests.deleteOrdersList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.order_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Orders Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readOrders() {
				this.loading = true
				requests.getAllOrders().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectOrders(i) {
				this.selected_orders = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    